import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { RiDeleteBinFill } from "react-icons/ri";
import { MdLocationPin, MdOutlinePhoneIphone } from "react-icons/md";
import logo from '../assets/images/qrcode.png';
import { StateContext } from "./contexts/StateContext";
import QRCode from 'qrcode';
import QrModal from './QrModal';
import RemoveModal from './RemoveModal';

function Show() {
  const { qrcodes,setSrc, showModal, setShowModal, setQrCode, 
    showRemoveModal, setShowRemoveModal} = useContext(StateContext);
 
  const handleShow = (item) => {
    setQrCode(item);
    QRCode.toDataURL(item.url, (err, url) => {
      if (err) { console.log(err); }
      else {
        setSrc(url);
        setShowModal(true)
      }
    })
  }

  const removeQr = (item) => {
    setQrCode(item);
    setShowRemoveModal(true);
  }
  return (
  <>
  {(qrcodes.length < 1) &&  <div className='center'>
    <p className='message'>Database is empty</p>
      </div>}
    <div className="fetchAll">
      {qrcodes?.map((item, index) =>
        <Card border="dark" style={{ width: '10rem', marginBottom: '15px', backgroundColor: '#cfe2f3' }} key={index}>
          <Card.Body>
            <Card.Title>{item.title}</Card.Title>
            <img src={logo} alt={item.title} />
            <div className='scan'>
              <div className="btn" onClick={() => handleShow(item)}>
                <div className='phone' >
                  <MdOutlinePhoneIphone />
                </div>
                <div className='pin'>
                  <MdLocationPin />
                </div>
              </div>
              <div className="btn delete" onClick={() => removeQr(item)}>
                <RiDeleteBinFill />
              </div>
            </div>
          </Card.Body>
        </Card>)}
      {showModal && <QrModal />}
      {showRemoveModal && <RemoveModal />}
    </div>
    </> 
  )
}
export default Show;
