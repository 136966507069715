import axios from 'axios';
// import config from '../config/config';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const getHome = async () => {
    const response = await axios.get(apiUrl + "/");
    return response.data;
}

const generateQRCode = (data) => {
    return axios.post(apiUrl + "/qrcode", data);
}
const getQRCode = async (id) => {
    const response = await axios.get(apiUrl + "/qrcode/" + id);
    return response.data;
}
const getQRCodes = async () => {
    const response = await axios.get(apiUrl + "/qrcode");
    return response.data;
}
const deleteQRCode = (id) => {
    return axios.delete(apiUrl + "/qrcode/" + id);
}
export { getHome, generateQRCode, getQRCode, getQRCodes, deleteQRCode }
