import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p><b>QR Code Generator &copy; Paragon</b></p>
        </div>
    )
}
export default Footer;
