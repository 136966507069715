import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import QRCode from 'qrcode';
import { StateContext } from "./contexts/StateContext";
import { Button, Form, Row, Col } from 'react-bootstrap';
import { generateQRCode } from '../api/qrcode';
import QrModal from './QrModal';

const Generate = () => {
  const { qrcodes, setQrCode, setSrc, setShowModal, showModal } = useContext(StateContext);
  const [apiError, setApiError] = useState("");

  const isValidUrl = (url) => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }

  const uniqueTitle = (values) => {
    const result = qrcodes.filter(({ title }) => title === values.title);
    if (result.length === 0) return true;
    else return false;
  }

  const uniqueUrl = (values) => {
    const result = qrcodes.filter(({ url }) => url === values.url);
    if (result.length === 0) return true;
    else return false;
  }

  return (
    <>
      <Formik initialValues={{
        title: "",
        url: ""
      }}
        onSubmit={async (values) => {
          const { title, url } = values;
          const res = await generateQRCode({ title, url })
          if (res.data) {
            setQrCode(res.data);
            QRCode.toDataURL(res.data.url, (err, url) => {
              if (err) { console.log(err); }
              else { setSrc(url); }
              setShowModal(true)
            })
          } else {
            setApiError(res.error)
          }
        }}
        validate={(values) => {
          const errors = {};
          if (!values.title) { errors.title = "*Required"; }
          else if (values.title.length < 3) { errors.title = "Title is to short"; }
          else if (values.title.length > 25) { errors.title = "Title is to long"; }
          else if (!/^[A-Za-z0-9 ]*$/i.test(values.title)) {
            errors.title = "Use only letters or numbers";
          } else if (!uniqueTitle(values)) { errors.title = "Title must be unique" }
          if (!values.url) { errors.url = "*Required"; }
          else if (!isValidUrl(values.url)) { errors.url = "Url is not valid" }
          else if (!uniqueUrl(values)) { errors.url = "Url must be unique" }
          return (errors);
        }}
      >
        {({
          values,
          handleSubmit,
          isValid,
          dirty,
          isSubmitting,
          handleBlur,
          handleChange,
          touched,
          errors
        }) => (
          <div className='d-flex justify-content-center'>
            <Form className=" border p-5  mt-5 rounded" onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-5" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Col sm={12} lg={12}>
                <Form.Control  lg={12} sm={1}
                  name='title'
                  type="text"
                  value={values.title}
                  placeholder='Enter title'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={touched.title && errors.title ? "error" : null}
                />
                {touched.title && errors.title
                  ? (<div className="error-message">{errors.title}</div>) : null}
                  </Col> 
              </Form.Group>
             
              <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Url</Form.Label>
                <Form.Control
                  name='url'
                  type="text"
                  placeholder="Enter URL in http://www.example.com format"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={touched.url && errors.url ? "error" : null}
                />
                {touched.url && errors.url
                  ? (<div className="error-message">{errors.url}</div>) : null}
              </Form.Group>
              <Button type="submit" disabled={!(isValid && dirty) || isSubmitting}>
                Submit
              </Button>
              {apiError ? (<div className="error-message">{apiError}</div>) : null}
            </Form>
          </div>
        )}
      </Formik>
      {showModal && <QrModal />}
    </>
  );
}
export default Generate;

