import React, { useContext } from 'react';
import { Routes, Route } from "react-router-dom";
import '../assets/styles/app.css';
import Header from './Header';
import Home from './Home';
import Show from './Show';
import Footer from './Footer';
import { StateContext } from '../component/contexts/StateContext';
import Generate from './Generate';

function MainRouter() {
  const { showCode, main } = useContext(StateContext);

  return (
    <>
      <Header />
      <Routes>
        <Route exact path='/' element={!main && <Home />} />
        <Route path='/qrcode' element={showCode ? <Show /> : <Generate />} />
        <Route path='/qrcode/:id' element={<Show />} />
      </Routes>
      <Footer />
    </>
  )
}
export default MainRouter;