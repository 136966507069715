import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { StateContext } from "./contexts/StateContext";
import { GrAlert } from "react-icons/gr";
import { deleteQRCode } from '../api/qrcode';
import { AiOutlineCheck } from 'react-icons/ai';

function RemoveModal() {
  const { showRemoveModal, qrCode, setShowRemoveModal, setShowModal, setShowCode } = useContext(StateContext);
  const [deleteQr, setDeleteQr] = useState(false);

  const remove = async (item) => {
    const res = await deleteQRCode(item._id);
    if (res.data) {
      setDeleteQr(true);
    } else {
      console.log(res.err)
    }
  }

  useEffect(() => {
    if (deleteQr) {
      const timer = setTimeout(() => {
        setDeleteQr(false);
        setShowRemoveModal(false);
        setShowModal(false);
        setShowCode(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [deleteQr]);

  return (
    <Modal show={showRemoveModal}>
      {!deleteQr ? <>
        <Modal.Header>
          <Modal.Title>Delete!</Modal.Title>
        </Modal.Header>
        <Modal.Body><GrAlert className='m-3'/> Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => remove(qrCode)}>
            OK
          </Button>
          <Button variant="primary" onClick={() => setShowRemoveModal(false)}>
            Cancel
          </Button>
        </Modal.Footer> </>
        : <Modal.Body className="confirmDelete">
          <Row>The QR code</Row>
          <Row>{qrCode.title}</Row>
          <Row>was deleted successufully!</Row>
          <AiOutlineCheck />
        </Modal.Body>}
    </Modal>
  )
}
export default RemoveModal;
