import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { StateContext } from "./contexts/StateContext";
import { CiUndo } from "react-icons/ci";
import { FiDownload } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import RemoveModal from './RemoveModal';

function QrModal() {
  const { qrCode, src, showModal, setShowModal, setShowCode, showRemoveModal, setShowRemoveModal } = useContext(StateContext);

  const remove = (item) => {
    setShowRemoveModal(true);
  }

  const back = () => {
    setShowCode(true)
    setShowModal(false);
  }

  return (
    <div>
      <Modal show={showModal} >
        <Modal.Title className='code'>
          <p>Scan the QR Code to access our location!</p>
          <p>Open the location in mobile browser</p>
        </Modal.Title>
        <Modal.Body className='d-flex flex-column mx-auto '>
          {qrCode &&
            <>
              <strong className='mx-auto'>{qrCode.title}</strong>
              <div className='image'>
                <img src={src} alt={qrCode.title} />
              </div>
            </>}
        </Modal.Body>
        <div className='code-svg'>
          <div className="btn back" onClick={() => back()}>
            <CiUndo />
          </div>
          <div className="btn down ">
            <a href={src} download="qrcode.png"><FiDownload /></a>
          </div>
          <div className="btn delete" onClick={() => remove()} >
            <RiDeleteBinFill />
          </div>
        </div>
      </Modal>
      {showRemoveModal && <RemoveModal />}
    </div>
  )
}
export default QrModal;
