import React, { useContext, useRef } from 'react';
import logo from '../assets/images/qrcode.png';
import { Link, useNavigate } from 'react-router-dom';
import { StateContext } from "./contexts/StateContext";
import { Button, Form, InputGroup, Navbar } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import Home from './Home';

function Header() {
  const navigate = useNavigate();
  const { qrcodes,setShowCode, main,setMain, setSearchData } = useContext(StateContext);
  const valueRef= useRef()

  const navigateHome = () => {
    setMain(false);
    navigate('/')
  }
  const navigateSearch = () => {
    setMain(true);
    navigate('/')
  }
  const onChange =  (e) => {
    const data = qrcodes.filter((el) => {return el.title?.toLowerCase().includes(valueRef.current.value?.toLowerCase())});
     setSearchData(data); 
    }
    const navigateGenerate = () => {
      setShowCode(false)
      setMain(false);
      navigate('/qrcode')
  }
    
    const navigateShow = () => {
      setShowCode(true)
      setMain(false);
      navigate('/qrcode')
    }
  
  return (
<div className='container'>
    <div className='header' >
      <Navbar sticky='top'> 

    <Navbar.Brand className='logo' onClick={() => navigateHome()}><img src={logo} alt={logo} /></Navbar.Brand>
      <div className='input'>
    
         <InputGroup className=''><CiSearch />
          <Form.Control placeholder="Search" aria-label="Search" ref={valueRef} onChange={onChange} onClick={() => navigateSearch()}/>
          </InputGroup>
          </div>
          <div className='d-flex p-3 justify-content-between btnHeader'>
           <Button className="btnMargin" variant="outline-secondary" onClick={() => navigateGenerate()}>
           <Link to="/qrcode">Generate</Link>
          </Button>
          <Button variant="outline-secondary" onClick={() => navigateShow()}>
            <Link to="/qrcode" >Show</Link>
          </Button> 
          </div>
     </Navbar>
     {main && <Home />} 
     </div>
     </div>

  )
}
export default Header;
