import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter} from "react-router-dom";
import '../assets/styles/app.css';
import { StateContextProvider } from '../component/contexts/StateContext';
import MainRouter from './MainRouter';

function App() {
  return (
    <Container>
      <StateContextProvider>
        <BrowserRouter>
  <MainRouter />
        </BrowserRouter>
      </StateContextProvider>
    </Container>
  );

}
export default App;
