import React,{createContext, useState, useEffect} from 'react';
import { getQRCodes } from '../../api/qrcode';

const StateContext = createContext();

const StateContextProvider = ({ children }) => {
  const [main, setMain] = useState(false)
    const [qrcodes, setQrcodes] = useState([]);
    const [qrCode, setQrCode] = useState({})
    const [code, setCode] = useState(null);
    const [showCode, setShowCode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [src, setSrc] = useState("");
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(0);
    
    const getQr = async () =>{
      let response;
      response = await getQRCodes();
      if(response) {
        setQrcodes(response);
      }
     }

    useEffect(()=> {
      getQr();
      if(qrcodes === 0){
        getQr();
      }
    },[qrcodes]);

     const state = { main, setMain,qrcodes, setQrcodes, showCode, setShowCode, src,setSrc,
        qrCode, setQrCode, code, setCode, showModal, setShowModal, showRemoveModal, setShowRemoveModal,
        searchData, setSearchData, message,setMessage,open, setOpen };

      return (
        <StateContext.Provider value={state}>
          {children}
        </StateContext.Provider>
      );
    };
    export { StateContext, StateContextProvider };

    