import React, { useContext } from 'react';
import logo from '../assets/images/qrcode.png';
import location from '../assets/images/location.jpg';
import { Card, Container } from 'react-bootstrap';
import { StateContext } from "./contexts/StateContext";
import { RiDeleteBinFill } from "react-icons/ri";
import { MdLocationPin, MdOutlinePhoneIphone } from "react-icons/md";
import QrModal from './QrModal';
import RemoveModal from './RemoveModal';
import QRCode from 'qrcode';

function Home() {
  const { main, setQrCode,setSrc,searchData, showModal, setShowRemoveModal,showRemoveModal,setShowModal } = useContext(StateContext);
  const handleShow = (item) => {
    setQrCode(item);
    QRCode.toDataURL(item.url, (err, url) => {
      if (err) { console.log(err); }
      else {
        setSrc(url);
        setShowModal(true)
      }
    })
  }

  const removeQr = (item) => {
    setQrCode(item);
    setShowRemoveModal(true);
  }
  return (
    <>
      {!main ? 
        <Container className="d-flex justify-content-evenly mb-3 p-3 ">
       <div className='image'>
       <p>QR Code</p>
       <img src={logo} alt='imageHome'/></div>
     <div className='image' >
       <p>QR Code title</p>
       <img src={location} alt='imageLocation'/></div>
       
    </Container>
      
       :  <div className="fetchAll">
       {searchData?.map((item, index) =>
         <Card border="dark" style={{ width: '10rem', marginBottom: '15px', backgroundColor: '#cfe2f3' }} key={index}>
           <Card.Body>
             <Card.Title>{item.title}</Card.Title>
             <img src={logo} alt={item.title} />
             <div className='scan'>
               <div className="btn" onClick={() => handleShow(item)}>
                 <div className='phone' >
                   <MdOutlinePhoneIphone />
                 </div>
                 <div className='pin'>
                   <MdLocationPin />
                 </div>
               </div>
               <div className="btn delete" onClick={() => removeQr(item)}>
                 <RiDeleteBinFill />
               </div>
             </div>
           </Card.Body>
         </Card>)}
       {showModal && <QrModal/>}
       {showRemoveModal && <RemoveModal />}
     </div>
}
</>
  )
}
export default Home;
